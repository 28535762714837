var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$currentUser.isTeacher
    ? _c("base-toolbar", {
        scopedSlots: _vm._u(
          [
            {
              key: "toolbar-right",
              fn: function() {
                return [
                  _c(
                    "pb-btn",
                    {
                      staticClass: "ml-2 my-1",
                      attrs: {
                        color: "primary",
                        label:
                          _vm.$t("addLabel") + " " + _vm.$t("classworkLabel"),
                        text: "",
                        outlined: ""
                      },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.addClasswork.apply(null, arguments)
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("fal fa-plus")])],
                    1
                  ),
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        bottom: "",
                        "offset-y": "",
                        "close-on-click": ""
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var onMenu = ref.on
                              return [
                                _c(
                                  "pb-btn",
                                  _vm._g(
                                    {
                                      staticClass: "ml-2 my-1",
                                      attrs: {
                                        color: "primary",
                                        label: _vm.$t("actionsLabel"),
                                        text: "",
                                        outlined: ""
                                      }
                                    },
                                    Object.assign({}, onMenu)
                                  ),
                                  [
                                    _c("v-icon", [
                                      _vm._v("fal fa-ellipsis-stroke-vertical")
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        648777904
                      )
                    },
                    [
                      _c(
                        "v-list",
                        [
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function($event) {
                                  _vm.dialog.scoreWeight = true
                                }
                              }
                            },
                            [
                              _c(
                                "v-btn",
                                { attrs: { text: "", color: "primary" } },
                                [
                                  _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v("fal fa-chart-pie")
                                  ]),
                                  _c("span", { staticClass: "ml-1" }, [
                                    _vm._v(_vm._s(_vm.$t("scoreWeightLabel")))
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        bottom: "",
                        "offset-y": "",
                        "close-on-click": ""
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var onMenu = ref.on
                              return [
                                _c(
                                  "pb-btn",
                                  _vm._g(
                                    {
                                      staticClass: "ml-2 my-1",
                                      attrs: {
                                        color: "primary",
                                        label: _vm.$t("sendToLabel"),
                                        text: "",
                                        outlined: ""
                                      }
                                    },
                                    Object.assign({}, onMenu)
                                  ),
                                  [
                                    _c("v-icon", [
                                      _vm._v("fal fa-arrow-up-from-square")
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2944834799
                      )
                    },
                    [
                      _c(
                        "v-list",
                        [
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function($event) {
                                  _vm.showPrintGrades(
                                    _vm.$t("csvLabel").toString()
                                  )
                                }
                              }
                            },
                            [
                              _c(
                                "v-btn",
                                { attrs: { color: "primary", text: "" } },
                                [
                                  _c("span", { staticClass: "ml-1" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("gradesSummaryLabel")) +
                                        " (" +
                                        _vm._s(_vm.$t("csvLabel")) +
                                        ")"
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function($event) {
                                  _vm.showPrintGrades(
                                    _vm.$t("pdfLabel").toString()
                                  )
                                }
                              }
                            },
                            [
                              _c(
                                "v-btn",
                                { attrs: { color: "primary", text: "" } },
                                [
                                  _c("span", { staticClass: "ml-1" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("gradesSummaryLabel")) +
                                        " (" +
                                        _vm._s(_vm.$t("pdfLabel")) +
                                        ")"
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function($event) {
                                  _vm.dialog.performanceReport = true
                                }
                              }
                            },
                            [
                              _c(
                                "v-btn",
                                { attrs: { color: "primary", text: "" } },
                                [
                                  _c("span", { staticClass: "ml-1" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("performanceReportsLabel")
                                      ) +
                                        " (" +
                                        _vm._s(_vm.$t("pdfLabel")) +
                                        ")"
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "toolbar-left",
              fn: function() {
                return [
                  !_vm.localIsMenuOverlapping
                    ? _c("v-select", {
                        staticStyle: { "max-width": "180px" },
                        attrs: {
                          label: _vm.$t("classLabel"),
                          dense: "",
                          "display:inline-flex": "",
                          "return-object": "",
                          "hide-details": "auto",
                          items: _vm.classList,
                          outlined: ""
                        },
                        model: {
                          value: _vm.selectedClass,
                          callback: function($$v) {
                            _vm.selectedClass = $$v
                          },
                          expression: "selectedClass"
                        }
                      })
                    : _vm._e(),
                  !_vm.localIsMenuOverlapping
                    ? _c("v-select", {
                        staticClass: "ml-2",
                        staticStyle: { "max-width": "180px" },
                        attrs: {
                          label: _vm.$t("gradePeriodLabel"),
                          dense: "",
                          "display:inline-flex": "",
                          "return-object": "",
                          "hide-details": "auto",
                          items: _vm.periodList,
                          "item-text": "name",
                          outlined: ""
                        },
                        model: {
                          value: _vm.selectedPeriod,
                          callback: function($$v) {
                            _vm.selectedPeriod = $$v
                          },
                          expression: "selectedPeriod"
                        }
                      })
                    : _vm._e(),
                  _c(
                    "v-menu",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isMenuOverlapping(),
                          expression: "!isMenuOverlapping()"
                        }
                      ],
                      attrs: {
                        bottom: "",
                        "offset-y": "",
                        "close-on-click": ""
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var onMenu = ref.on
                              return [
                                _c(
                                  "pb-btn",
                                  _vm._g(
                                    {
                                      staticClass: "ml-2 my-1",
                                      attrs: {
                                        color: "primary",
                                        label: _vm.$t("filterLabel"),
                                        text: "",
                                        outlined: "",
                                        id: "gradesFilterMenu"
                                      }
                                    },
                                    Object.assign({}, onMenu)
                                  ),
                                  [_c("v-icon", [_vm._v("fal fa-sliders")])],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2676327062
                      )
                    },
                    [
                      _c(
                        "v-list",
                        [
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function($event) {
                                  _vm.showStandards = !_vm.showStandards
                                }
                              }
                            },
                            [
                              _c(
                                "v-btn",
                                { attrs: { color: "primary", text: "" } },
                                [
                                  _vm.showStandards
                                    ? [
                                        _c("v-icon", { attrs: { small: "" } }, [
                                          _vm._v("fal fa-eye-slash")
                                        ]),
                                        _c("span", { staticClass: "ml-1" }, [
                                          _vm._v(
                                            _vm._s(_vm.$t("hideStandardsLabel"))
                                          )
                                        ])
                                      ]
                                    : [
                                        _c("v-icon", { attrs: { small: "" } }, [
                                          _vm._v("fal fa-eye")
                                        ]),
                                        _c("span", { staticClass: "ml-1" }, [
                                          _vm._v(
                                            _vm._s(_vm.$t("showStandardsLabel"))
                                          )
                                        ])
                                      ]
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "mobile-toolbar-right",
              fn: function() {
                return [
                  _vm.viewItem === null
                    ? _c("search-filter", {
                        ref: "searchFilter",
                        attrs: {
                          filterCount: _vm.mobileFilterCount,
                          isFiltered: _vm.mobileIsFiltered,
                          clearFilter: _vm.mobileClearFilter,
                          mobileMode: true
                        },
                        on: { focusMenu: _vm.focusMenu },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "filters-menu",
                              fn: function() {
                                return [
                                  _c(
                                    "v-chip-group",
                                    {
                                      staticClass: "ml-2 mt-2",
                                      attrs: {
                                        "active-class":
                                          "accent3--text font-weight-bold",
                                        column: "",
                                        multiple: ""
                                      },
                                      model: {
                                        value: _vm.mobileFilters,
                                        callback: function($$v) {
                                          _vm.mobileFilters = $$v
                                        },
                                        expression: "mobileFilters"
                                      }
                                    },
                                    [
                                      _c(
                                        "pb-chip",
                                        {
                                          ref: "firstMenuItem",
                                          attrs: { filter: "", outlined: "" },
                                          on: {
                                            keyup: [
                                              function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "escape",
                                                    undefined,
                                                    $event.key,
                                                    undefined
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.focusButton.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                              function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.arrayAddOrRemove(
                                                  _vm.mobileFilters,
                                                  0
                                                )
                                              }
                                            ]
                                          }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("standardsLabel"))
                                            )
                                          ])
                                        ]
                                      ),
                                      _c(
                                        "pb-chip",
                                        {
                                          attrs: { filter: "", outlined: "" },
                                          on: {
                                            keyup: [
                                              function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "escape",
                                                    undefined,
                                                    $event.key,
                                                    undefined
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.focusButton.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                              function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.arrayAddOrRemove(
                                                  _vm.mobileFilters,
                                                  1
                                                )
                                              }
                                            ]
                                          }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("assignmentsLabel"))
                                            )
                                          ])
                                        ]
                                      ),
                                      _c(
                                        "pb-chip",
                                        {
                                          attrs: { filter: "", outlined: "" },
                                          on: {
                                            keyup: [
                                              function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "escape",
                                                    undefined,
                                                    $event.key,
                                                    undefined
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.focusButton.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                              function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.arrayAddOrRemove(
                                                  _vm.mobileFilters,
                                                  2
                                                )
                                              }
                                            ]
                                          }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("assessmentsLabel"))
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          1181878526
                        )
                      })
                    : _vm.singleStudent
                    ? _c("search-filter", {
                        ref: "searchFilter",
                        attrs: {
                          filterCount: _vm.showStandards ? 0 : 1,
                          isFiltered: _vm.showStandards === false,
                          clearFilter: function() {
                            _vm.showStandards = true
                          },
                          mobileMode: true
                        },
                        on: { focusMenu: _vm.focusMenu },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "filters-menu",
                              fn: function() {
                                return [
                                  _c(
                                    "v-chip-group",
                                    {
                                      staticClass: "ml-2 mt-2",
                                      attrs: {
                                        "active-class":
                                          "accent3--text font-weight-bold",
                                        column: ""
                                      },
                                      model: {
                                        value: _vm.showStandardsVal,
                                        callback: function($$v) {
                                          _vm.showStandardsVal = $$v
                                        },
                                        expression: "showStandardsVal"
                                      }
                                    },
                                    [
                                      _c(
                                        "pb-chip",
                                        {
                                          ref: "firstMenuItem",
                                          attrs: { filter: "", outlined: "" },
                                          on: {
                                            keyup: [
                                              function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "escape",
                                                    undefined,
                                                    $event.key,
                                                    undefined
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.focusButton.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                              function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                _vm.showStandards = !_vm.showStandards
                                              }
                                            ]
                                          }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("standardsLabel"))
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          1992282150
                        )
                      })
                    : _vm._e()
                ]
              },
              proxy: true
            }
          ],
          null,
          false,
          463783080
        )
      })
    : _vm.$currentUser.isStudent
    ? _c("base-toolbar", {
        scopedSlots: _vm._u([
          {
            key: "toolbar-left",
            fn: function() {
              return [
                _vm.schoolConnected && !_vm.loadingYears
                  ? _c("v-select", {
                      staticStyle: { "max-width": "180px" },
                      attrs: {
                        label: _vm.$t("schoolYearLabel"),
                        "display:inline-flex": "",
                        "return-object": "",
                        "hide-details": "auto",
                        items: _vm.yearOptions,
                        "item-text": "yearName",
                        outlined: "",
                        dense: ""
                      },
                      model: {
                        value: _vm.selectedYear,
                        callback: function($$v) {
                          _vm.selectedYear = $$v
                        },
                        expression: "selectedYear"
                      }
                    })
                  : !_vm.loadingYears
                  ? _c("pb-date-field", {
                      staticStyle: { "max-width": "120px" },
                      attrs: {
                        value: _vm.dateText,
                        hideDetails: "auto",
                        required: "",
                        "display:inline-flex": "",
                        outlined: "",
                        dense: ""
                      },
                      on: {
                        "update:value": function($event) {
                          _vm.dateText = $event
                        }
                      }
                    })
                  : _vm._e()
              ]
            },
            proxy: true
          },
          {
            key: "toolbar-right",
            fn: function() {
              return [
                _c(
                  "v-menu",
                  {
                    attrs: { bottom: "", "offset-y": "", "close-on-click": "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function(ref) {
                          var onMenu = ref.on
                          return [
                            _c(
                              "pb-btn",
                              _vm._g(
                                {
                                  staticClass: "ml-2 my-1",
                                  attrs: {
                                    color: "primary",
                                    label: _vm.$t("sendToLabel"),
                                    text: "",
                                    outlined: ""
                                  }
                                },
                                Object.assign({}, onMenu)
                              ),
                              [
                                _c("v-icon", [
                                  _vm._v("fal fa-arrow-up-from-square")
                                ])
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c(
                      "v-list",
                      [
                        _c(
                          "v-list-item",
                          {
                            on: {
                              click: function($event) {
                                _vm.showPrintGrades(_vm.$t("CSV").toString())
                              }
                            }
                          },
                          [
                            _c(
                              "v-btn",
                              { attrs: { color: "primary", text: "" } },
                              [
                                _c("span", { staticClass: "ml-1" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("gradesSummaryLabel")) +
                                      " (" +
                                      _vm._s(_vm.$t("CSV")) +
                                      ")"
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-list-item",
                          {
                            on: {
                              click: function($event) {
                                _vm.showPrintGrades(_vm.$t("PDF").toString())
                              }
                            }
                          },
                          [
                            _c(
                              "v-btn",
                              { attrs: { color: "primary", text: "" } },
                              [
                                _c("span", { staticClass: "ml-1" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("gradesSummaryLabel")) +
                                      " (" +
                                      _vm._s(_vm.$t("PDF")) +
                                      ")"
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-list-item",
                          {
                            on: {
                              click: function($event) {
                                _vm.dialog.performanceReport = true
                              }
                            }
                          },
                          [
                            _c(
                              "v-btn",
                              { attrs: { color: "primary", text: "" } },
                              [
                                _c("span", { staticClass: "ml-1" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("performanceReportsLabel")) +
                                      " (" +
                                      _vm._s(_vm.$t("PDF")) +
                                      ")"
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    : _c("base-toolbar", {
        attrs: { searchable: _vm.isSearchable, search: _vm.searchText },
        on: {
          "update:search": function($event) {
            _vm.searchText = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "mobile-search-filters",
            fn: function() {
              return [
                _c("search-filter", {
                  ref: "searchFilter",
                  attrs: {
                    filterCount: _vm.filterCount,
                    isFiltered: _vm.isFiltered,
                    clearFilter: _vm.clearFilter,
                    mobileMode: true
                  },
                  on: { focusMenu: _vm.focusMenu },
                  scopedSlots: _vm._u([
                    {
                      key: "filters-menu",
                      fn: function() {
                        return [
                          _c("v-select", {
                            ref: "firstMenuItem",
                            staticClass: "ml-2",
                            staticStyle: { "max-width": "195px" },
                            attrs: {
                              label: _vm.$t("gradeLevelLabel"),
                              "display:inline-flex": "",
                              "return-object": "",
                              "hide-details": "auto",
                              items: _vm.gradesList,
                              "item-text": "text",
                              outlined: "",
                              dense: ""
                            },
                            on: {
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "escape",
                                    undefined,
                                    $event.key,
                                    undefined
                                  )
                                ) {
                                  return null
                                }
                                return _vm.focusButton.apply(null, arguments)
                              }
                            },
                            model: {
                              value: _vm.showLevel,
                              callback: function($$v) {
                                _vm.showLevel = $$v
                              },
                              expression: "showLevel"
                            }
                          })
                        ]
                      },
                      proxy: true
                    }
                  ])
                })
              ]
            },
            proxy: true
          },
          {
            key: "toolbar-right",
            fn: function() {
              return [
                _c(
                  "v-menu",
                  {
                    attrs: { bottom: "", "offset-y": "", "close-on-click": "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function(ref) {
                          var onMenu = ref.on
                          return [
                            _c(
                              "pb-btn",
                              _vm._g(
                                {
                                  staticClass: "ml-2 my-1",
                                  attrs: {
                                    color: "primary",
                                    label: _vm.$t("sendToLabel"),
                                    text: "",
                                    outlined: ""
                                  }
                                },
                                Object.assign({}, onMenu)
                              ),
                              [
                                _c("v-icon", [
                                  _vm._v("fal fa-arrow-up-from-square")
                                ])
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c(
                      "v-list",
                      [
                        _c(
                          "v-list-item",
                          {
                            on: {
                              click: function($event) {
                                _vm.performanceReportDialog = true
                              }
                            }
                          },
                          [
                            _c(
                              "v-btn",
                              { attrs: { color: "primary", text: "" } },
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("fal fa-square-list")
                                ]),
                                _c("span", { staticClass: "ml-1" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("performanceReportsLabel"))
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "toolbar-left",
            fn: function() {
              return [
                _c(
                  "v-col",
                  {
                    staticClass: "px-0",
                    style: { marginRight: _vm.searchWidthGrow + "px" },
                    attrs: { md: "2", sm: "3" }
                  },
                  [
                    _c("search-filter", {
                      ref: "searchFilter",
                      attrs: {
                        filterCount: _vm.filterCount,
                        isFiltered: _vm.isFiltered,
                        clearFilter: _vm.clearFilter,
                        searchText: _vm.searchText,
                        searchWidthGrow: _vm.searchWidthGrow,
                        mobileMode: false
                      },
                      on: {
                        "update:searchText": function($event) {
                          _vm.searchText = $event
                        },
                        "update:search-text": function($event) {
                          _vm.searchText = $event
                        },
                        focusMenu: _vm.focusMenu
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "filters-menu",
                          fn: function() {
                            return [
                              _c("v-select", {
                                ref: "firstMenuItem",
                                staticClass: "ml-2",
                                staticStyle: { "max-width": "195px" },
                                attrs: {
                                  label: _vm.$t("gradeLevelLabel"),
                                  "display:inline-flex": "",
                                  "return-object": "",
                                  "hide-details": "auto",
                                  items: _vm.gradesList,
                                  "item-text": "text",
                                  outlined: "",
                                  dense: ""
                                },
                                on: {
                                  keyup: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "escape",
                                        undefined,
                                        $event.key,
                                        undefined
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.focusButton.apply(
                                      null,
                                      arguments
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.showLevel,
                                  callback: function($$v) {
                                    _vm.showLevel = $$v
                                  },
                                  expression: "showLevel"
                                }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ])
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isMenuOverlapping(),
                        expression: "!isMenuOverlapping()"
                      }
                    ],
                    attrs: { id: "gradesFilterMenu" }
                  },
                  [
                    (_vm.$currentUser.isDistrictAdmin ||
                      _vm.haveMultipleSchools) &&
                    !_vm.localIsMenuOverlapping
                      ? _c("v-autocomplete", {
                          staticClass: "ml-2",
                          staticStyle: {
                            "max-width": "190px",
                            "min-width": "190px"
                          },
                          attrs: {
                            label: _vm.$t("schoolLabel"),
                            "display:inline-flex": "",
                            "hide-details": "auto",
                            items: _vm.schoolItems,
                            outlined: "",
                            dense: ""
                          },
                          model: {
                            value: _vm.currentSchoolId,
                            callback: function($$v) {
                              _vm.currentSchoolId = $$v
                            },
                            expression: "currentSchoolId"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }