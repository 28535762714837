





























































































































































































































































































































import BaseToolbar from '@/components/core/BaseToolbar.vue';
import CommonUtils from '@/utils/common-utils';
import DateTimeUtils from '@/utils/date-time-utils';
import { Component, Watch } from 'vue-property-decorator';
import SearchFilter from '@/components/core/SearchFilter.vue';

@Component({
  components: {
    BaseToolbar,
    SearchFilter
  }
})
export default class GradesToolbar extends BaseToolbar {
  searchWidthGrow = '0';
  mobileFilters = [0, 1, 2];

  $refs!: {
    searchFilter: SearchFilter
  }

  get localComponent(): any {
    return this.currentPageInstance || {};
  }

  get printGradesComponent() {
    return this.localComponent.printGradesRef || {};
  }

  get localTeacherGradesComponent() {
    return this.localComponent.teacherGradesRef || {};
  }

  get localAdminGradesComponent() {
    return this.localComponent.adminGradesRef || {};
  }

  get localStudentGradesComponent() {
    return this.localComponent.studentGradesRef || {};
  }

  get schoolConnected() {
    return this.localStudentGradesComponent.schoolConnected || false;
  }

  get selectedYear() {
    return this.localStudentGradesComponent.selectedYear || {};
  }

  set selectedYear(value: any) {
    this.localStudentGradesComponent.selectedYear = value;
  }

  get dateText() {
    return this.localStudentGradesComponent.dateText || DateTimeUtils.currentDateAddDays(0);
  }

  set dateText(val: any) {
    this.localStudentGradesComponent.dateText = val;
  }

  get yearOptions() {
    return this.localStudentGradesComponent.yearOptions || [];
  }

  get loadingYears() {
    return this.localStudentGradesComponent.loadingYears || false;
  }

  get selectedClass() {
    return this.localComponent.selectedClass || [];
  }

  set selectedClass(value: Array<any>) {
    this.localComponent.selectedClass = value;
    this.$store.commit('grades/setSelectedClass', value);
  }

  get classList() {
    return this.localComponent.classList || [];
  }

  get selectedPeriod() {
    return this.localComponent.selectedPeriod || 0;
  }

  set selectedPeriod(value: number) {
    this.localComponent.selectedPeriod = value;
  }

  get periodList() {
    return this.localComponent.periodList || [];
  }

  get addClasswork() {
    return this.localComponent.addClasswork || this.emptyFunction;
  }

  get showStandards() {
    return this.localTeacherGradesComponent.showStandards;
  }

  set showStandards(value: boolean) {
    this.localTeacherGradesComponent.showStandards = value;
  }

  get showAssignments() {
    return this.localTeacherGradesComponent.showAssignments;
  }

  set showAssignments(value: number) {
    this.localTeacherGradesComponent.showAssignments = value;
  }

  get showAssessments() {
    return this.localTeacherGradesComponent.showAssessments;
  }

  set showAssessments(value: number) {
    this.localTeacherGradesComponent.showAssessments = value;
  }

  get dialog() {
    return this.localComponent.dialog || {};
  }

  get showLevel() {
    return this.localAdminGradesComponent.showLevel;
  }

  set showLevel(value: boolean) {
    this.localAdminGradesComponent.showLevel = value;
  }

  get viewItem() {
    return this.localTeacherGradesComponent.viewItem;
  }

  get singleStudent() {
    return this.localTeacherGradesComponent.singleStudent;
  }

  get showStandardsVal() {
    return (this.showStandards) ? 0 : null;
  }

  set showStandardsVal(val: any) {
    this.showStandards = val === 0;
  }

  @Watch('mobileFilters')
  mobileFiltersUpdate() {
    this.showStandards = this.mobileFilters.includes(0);
    this.showAssignments = this.mobileFilters.includes(1) ? 0 : 1;
    this.showAssessments = this.mobileFilters.includes(2) ? 0 : 1;
  }

  get mobileIsFiltered() {
    return this.mobileFilters.length !== 3;
  }

  get mobileFilterCount() {
    return 3 - this.mobileFilters.length;
  }

  mobileClearFilter() {
    this.mobileFilters = [0, 1, 2];
  }

  get isFiltered() {
    if (CommonUtils.hasNoText(this.searchText) && this.showLevel === this.gradesList[0]) {
      this.searchWidthGrow = '0';
      return false;
    } else {
      this.searchWidthGrow = '40';
      return true;
    }
  }

  get filterCount() {
    var count = 0;
    if (CommonUtils.hasText(this.searchText)) {
      count += 1;
    }
    if (this.showLevel !== this.gradesList[0]) {
      count += 1;
    }
    return count;
  }

  clearFilter() {
    this.searchText = '';
    this.showLevel = this.gradesList[0];
  }

  focusMenu() {
    this.$nextTick(() => {
      new Promise(resolve => setTimeout(resolve, 100)).then(() => {
        try {
          const element = (this.$refs as any).firstMenuItem.$el;
          if (element.querySelector('input')) {
            element.querySelector('input').focus();
          } else {
            element.focus();
          }
        } catch (e) {
          console.log(this.$t('focusFailed'));
        }
      });
    });
  }

  focusButton() {
    this.$refs.searchFilter.focusButton();
  }

  get gradesList() {
    return this.localAdminGradesComponent.gradesList || [];
  }

  get isSearchable() {
    return (this.localAdminGradesComponent.individualStudent === null);
  }

  get searchText() {
    return this.localAdminGradesComponent.search || '';
  }

  set searchText(value: string) {
    this.localAdminGradesComponent.search = (value || '');
  }

  get performanceReportDialog() {
    return this.localAdminGradesComponent.performanceReportDialog;
  }

  set performanceReportDialog(value: boolean) {
    this.localAdminGradesComponent.performanceReportDialog = value;
  }

  get printGradesExportType() {
    return this.printGradesComponent.exportType;
  }

  set printGradesExportType(value: string) {
    this.printGradesComponent.exportType = value;
  }

  get isMenuOverlapping() {
    return this.localComponent.isMenuOverlapping || this.emptyFunction;
  }

  get localIsMenuOverlapping() {
    return this.localComponent.localIsMenuOverlapping || false;
  }

  get haveMultipleSchools():boolean {
    return this.localAdminGradesComponent.haveMultipleSchools || false;
  }

  get schoolItems() {
    return this.localAdminGradesComponent.schoolItems || [];
  }

  get currentSchoolId() {
    return this.localAdminGradesComponent.currentSchoolId;
  }

  set currentSchoolId(val:any) {
    this.localAdminGradesComponent.currentSchoolId = val;
  }

  get arrayAddOrRemove() {
    return CommonUtils.arrayAddOrRemove;
  }

  showPrintGrades(exportType: string) {
    this.$eventBus.$emit('showLocalPrintOptions');
    this.$nextTick(() => {
      this.printGradesExportType = exportType;
    })
  }
}
