var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("base-toolbar", {
    attrs: { searchable: true, search: _vm.searchText },
    on: {
      "update:search": function ($event) {
        _vm.searchText = $event
      },
    },
    scopedSlots: _vm._u([
      {
        key: "toolbar-left",
        fn: function () {
          return [
            _c(
              "v-col",
              { staticClass: "px-0", attrs: { md: "2", sm: "3" } },
              [
                _c("v-text-field", {
                  attrs: {
                    outlined: "",
                    dense: "",
                    clearable: "",
                    "single-line": "",
                    "hide-details": "",
                    "prepend-inner-icon": "fal fa-search",
                    label: _vm.$t("searchLabel"),
                  },
                  model: {
                    value: _vm.searchText,
                    callback: function ($$v) {
                      _vm.searchText = $$v
                    },
                    expression: "searchText",
                  },
                }),
              ],
              1
            ),
            !_vm.localIsMenuOverlapping
              ? _c(
                  "v-menu",
                  {
                    attrs: {
                      transition: "scroll-y-transition",
                      "offset-y": "",
                      "close-on-content-click": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "ml-2",
                                      attrs: {
                                        outlined: "",
                                        height: "40",
                                        color: "info",
                                      },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_vm._v(" " + _vm._s(_vm.dateText) + " ")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3995711823
                    ),
                    model: {
                      value: _vm.showDatePicker,
                      callback: function ($$v) {
                        _vm.showDatePicker = $$v
                      },
                      expression: "showDatePicker",
                    },
                  },
                  [
                    _c("v-date-picker", {
                      ref: "datePicker",
                      attrs: { locale: _vm.getGlobalLanguage },
                      on: {
                        change: function ($event) {
                          return _vm.refresh(false)
                        },
                      },
                      model: {
                        value: _vm.date,
                        callback: function ($$v) {
                          _vm.date = $$v
                        },
                        expression: "date",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.localIsMenuOverlapping
              ? _c(
                  "v-menu",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isMenuOverlapping(),
                        expression: "!isMenuOverlapping()",
                      },
                    ],
                    attrs: {
                      transition: "scroll-y-transition",
                      "offset-y": "",
                      "close-on-content-click": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "ml-2",
                                      attrs: {
                                        outlined: "",
                                        color: _vm.lessonMenuColor,
                                        height: "40",
                                        id: "attendanceLessonMenu",
                                      },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.lessonMenuLabel) + " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2454261044
                    ),
                  },
                  [
                    _c(
                      "perfect-scrollbar",
                      { staticStyle: { "max-height": "calc(100vh - 300px)" } },
                      [
                        _c(
                          "v-list",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.isLessonsEmpty,
                                expression: "!isLessonsEmpty",
                              },
                            ],
                          },
                          [
                            _c(
                              "v-list-item-group",
                              {
                                staticClass: "menu-list",
                                attrs: {
                                  mandatory: "",
                                  "aria-label": _vm.$t("lessonAttendanceLabel"),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.refresh(false)
                                  },
                                },
                                model: {
                                  value: _vm.selectedLessonIdx,
                                  callback: function ($$v) {
                                    _vm.selectedLessonIdx = $$v
                                  },
                                  expression: "selectedLessonIdx",
                                },
                              },
                              [
                                _c(
                                  "v-list-item",
                                  [
                                    _c(
                                      "v-list-item-title",
                                      { staticClass: "px-2" },
                                      [_vm._v(_vm._s(_vm.$t("allDayLabel")))]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._l(_vm.lessons, function (lesson) {
                                  return _c(
                                    "v-list-item",
                                    { key: lesson.subjectId },
                                    [
                                      _c(
                                        "v-list-item-title",
                                        {
                                          staticClass:
                                            "drop-down-list py-2 px-4 rounded-lg",
                                          class: _vm.highContrastMode
                                            ? "contrast-view"
                                            : "",
                                          style: {
                                            color: "white",
                                            backgroundColor: lesson.classColor,
                                          },
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(_vm._s(lesson.className)),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        },
        proxy: true,
      },
      {
        key: "toolbar-right",
        fn: function () {
          return undefined
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }